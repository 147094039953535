import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "atelier-headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1980, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    atelier: directusMediaCollection(name: {eq: "atelier"}) {
      name
        images: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 1600, quality: 95) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        thumbnails: media {
          sort
          file {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 300, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Carousel mdxType="Carousel">
  {props.data.headerImages.images.map(image => <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid} alt={image.file.localFile.name} mdxType="Img" />)}
    </Carousel>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Atelier`}</h1>
  <span style={{
        textAlign: 'justify'
      }}>
        <p>{`  In unserem hauseigenen Atelier fertigen unsere Goldschmiede mit viel Wissen in alter Handwerkstradition Unikate nach
Ihren Wünschen an. Die schmucken Stücke unserer hauseigenen Kollektion begeistern mit edlen Materialien, individuellem
Design und meisterlicher Qualität.`}</p>
  </span>
  <Lightbox images={props.data.atelier.images} thumbnails={props.data.atelier.thumbnails} fromApi mdxType="Lightbox" />
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      